export interface IQueryStack {
    productIds: string[];
    resolver(D?: null): void;
}

export class InventoryQueryStashingService {
    private debounceTimeout: number;
    public queryStack: IQueryStack[] = [];
    private stashedProducts: string[] = [];
    public beingProcessed: string[] = [];
    private processed: string[] = [];

    public debounce = (callback: () => void, timeoutValue: number) => {
        clearTimeout(this.debounceTimeout);

        this.debounceTimeout = setTimeout(callback, timeoutValue);
    };

    public isProcessing(productIds: string[]) {
        return productIds.every((id) => this.beingProcessed.includes(id));
    }

    public isStashed(productIds: string[]) {
        return productIds.every((id) => this.stashedProducts.includes(id));
    }

    public isInProgress(productIds: string[]) {
        return this.isProcessing(productIds) || this.isStashed(productIds);
    }

    public removeFromProcessed(productIds: string[]) {
        this.processed = this.processed.filter((id) => !productIds.includes(id));
    }

    public stashProducts(productIds: string[]) {
        this.stashedProducts = [...this.stashedProducts, ...productIds];
    }

    get storedIds() {
        return [...this.stashedProducts, ...this.processed];
    }

    public prepareInventoryQuery() {
        const idsToQuery = this.stashedProducts;
        this.processed = [...this.processed, ...this.stashedProducts];
        this.stashedProducts = [];

        return idsToQuery;
    }

    public createQueryPromise = (productIds: string[]) => {
        const requestPromise = new Promise((resolver) => {
            this.queryStack.push({ productIds, resolver });
        });

        return <Promise<null>>requestPromise;
    };

    private shouldResolve(query: IQueryStack, productIds: string[]) {
        const { productIds: promiseIds } = query;

        return promiseIds.every((id) => productIds.includes(id));
    }

    public resolveQueue = (productIds: string[]) => {
        this.queryStack.forEach((query: IQueryStack) => {
            const { resolver } = query;

            this.shouldResolve(query, productIds) && resolver();
        });

        this.beingProcessed = this.beingProcessed.filter((id) => !productIds.includes(id));
    };
}
