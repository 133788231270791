import { IPgInventoryParams } from 'src/internal/domain/entities/Config';

const JSON_RPC_BASE_PARAMS = {
    BYPASS_DATALAYER: 1
};

export const formatInventoryGetParam = (
    productIds: string[],
    pgInventoryParams: IPgInventoryParams
) => {
    const pgProductIds = productIds.map(function (id) {
        return `PROD${id}`;
    });

    return [
        {
            products: pgProductIds,
            product_fields: pgInventoryParams.productFields,
            sku_fields: pgInventoryParams.skuFields,
            bypass_datalayer: JSON_RPC_BASE_PARAMS.BYPASS_DATALAYER
        }
    ];
};
