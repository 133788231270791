import { IPGInventoryApiConfig, IPGResponse } from './IInventoryApiSdk';
import { formatInventoryGetParam } from './utils/utils';
import { IPgInventoryParams } from 'src/internal/domain/entities/Config';
import { JsonRPCApi, RpcMethods } from '@estee/elc-jsonrpc-api';
import { IResponse } from '@estee/elc-http-transport';

export class InventoryApiSdk extends JsonRPCApi {
    private pgInventoryParams: IPgInventoryParams;

    constructor({ pgInventoryParams, config, serviceName, serviceVersion }: IPGInventoryApiConfig) {
        super(config, serviceName, serviceVersion);
        this.pgInventoryParams = pgInventoryParams;
    }

    public getInventoryItems = async (productIds: string[]) => {
        const method = RpcMethods.PRODCAT_METHOD;
        const body = this.formatToRPC(
            method,
            formatInventoryGetParam(productIds, this.pgInventoryParams)
        );

        return (await this.fetch({ method, body })) as IResponse<IPGResponse[]>;
    };
}
