(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("HypoContainer"), require("mobx"), require("ElcServiceBus"), require("react"), require("ELCLogger"), require("HttpTransport"));
	else if(typeof define === 'function' && define.amd)
		define(["HypoContainer", "mobx", "ElcServiceBus", "react", "ELCLogger", "HttpTransport"], factory);
	else if(typeof exports === 'object')
		exports["pg-service-inventory"] = factory(require("HypoContainer"), require("mobx"), require("ElcServiceBus"), require("react"), require("ELCLogger"), require("HttpTransport"));
	else
		root["pg-service-inventory"] = factory(root["ElcRuntime"]["HypoContainer"], root["ElcRuntime"]["mobx"], root["ElcServiceBus"], root["ElcRuntime"]["React"], root["ElcRuntime"]["ELCLogger"], root["ElcRuntime"]["HttpTransport"]);
})(self, (__WEBPACK_EXTERNAL_MODULE_hypo_container__, __WEBPACK_EXTERNAL_MODULE_mobx__, __WEBPACK_EXTERNAL_MODULE__estee_elc_service_bus__, __WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE__estee_elc_logging__, __WEBPACK_EXTERNAL_MODULE__estee_elc_http_transport__) => {
return 