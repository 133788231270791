import { observable, action } from 'mobx';

export interface IPgInventoryParams {
    productFields: string[];
    skuFields: string[];
}

interface IInventoryServiceConfig {
    pgInventoryParams: IPgInventoryParams;
}

export interface IServiceConfig {
    inventoryConfig: IInventoryServiceConfig;
}

export class Config {
    @observable public config: IServiceConfig;

    constructor(config: IServiceConfig) {
        this.config = config;
    }

    @action
    public updateConfig(config: Partial<IServiceConfig>) {
        this.config = { ...this.config, ...config };
    }
}
