import { observable, action } from 'mobx';
import { Inventory } from '../domain/entities/Inventory';
import { InventoryApiSdk } from '../../api/InventoryApiSdk';
import { IInventoryRepository } from './IInventoryRepository';
import { formatInventoryFromJSONRPC } from './utils/utils';
import { InventoryQueryStashingService } from './InventoryQueryStashingService';
import { INVENTORY_QUERY_DEBOUNCE_VALUE } from './constants/InventoryRepository';

export interface IInventory {
    legacy?: boolean;
}

export interface IInventoryRepositoryConfig {
    inventory?: IInventory;
}

export interface IInventoryQueryResponse {
    productId: string;
    skuId: string;
    inventoryStatus: number;
    highVelocityQuantity?: number;
}

export class InventoryRepository {
    @observable public inventory: Inventory;
    @observable public inventories: Inventory[] = [];
    private apiSdk: InventoryApiSdk;
    private inventoryQueryStashingService: InventoryQueryStashingService;

    constructor({ inventory, apiSdk }: IInventoryRepository) {
        this.inventory = inventory;
        this.apiSdk = apiSdk;
        this.inventoryQueryStashingService = new InventoryQueryStashingService();
    }

    private filterNewProductIds(productIds: string[]) {
        const storedIds = this.inventoryQueryStashingService.storedIds;

        return productIds.filter((productId) => !storedIds.includes(productId));
    }

    @action
    private debouncedGet = async () => {
        const productIds = this.inventoryQueryStashingService.prepareInventoryQuery();
        this.inventoryQueryStashingService.beingProcessed = [
            ...this.inventoryQueryStashingService.beingProcessed,
            ...productIds
        ];

        try {
            const response = await this.apiSdk.getInventoryItems(productIds);

            if (response.data?.length !== 0) {
                const formattedInventory = formatInventoryFromJSONRPC({ response });

                this.setInventories(formattedInventory);
            }
        } catch (error) {
            this.inventoryQueryStashingService.removeFromProcessed(productIds);
            console.warn(new Error(error));
        }

        this.inventoryQueryStashingService.resolveQueue(productIds);
    };

    public filterInventories = async (productIds: string[]) => {
        return this.inventories.filter((inventory) => productIds.includes(inventory.productId));
    };

    public updateInventories = async (productIds: string[]) => {
        const newProductIds = this.filterNewProductIds(productIds);

        if (newProductIds.length > 0) {
            this.inventoryQueryStashingService.stashProducts(newProductIds);
            this.inventoryQueryStashingService.debounce(
                this.debouncedGet,
                INVENTORY_QUERY_DEBOUNCE_VALUE
            );

            return this.inventoryQueryStashingService.createQueryPromise(newProductIds);
        }
        if (this.inventoryQueryStashingService.isInProgress(productIds)) {
            return this.inventoryQueryStashingService.createQueryPromise(productIds);
        }
    };

    public getInventories = async (productIds: string[]) => {
        await this.updateInventories(productIds);

        return this.filterInventories(productIds);
    };

    @action
    private setInventories = (inventoryData: IInventoryQueryResponse[]) => {
        const inventory = inventoryData.map((skuInvItem: IInventoryQueryResponse) =>
            Inventory.fromJson(skuInvItem)
        );

        this.inventories = Array.from(
            [...this.inventories, ...inventory]
                .reduce((m, o) => m.set(o.skuId, o), new Map())
                .values()
        );
    };
}
