import { Container } from 'hypo-container';
import { getDecorators } from '@estee/elc-service';
import { Config } from '../internal/domain/entities/Config';
import { ServiceBusController } from './ServiceBusController';
import { InventoryRepository } from '../internal/repositories/InventoryRepository';
import { IInventoryRepository } from '../internal/repositories/IInventoryRepository';
import { InventoryService } from './InventoryService';
import { InventoryApiSdk } from '../api/InventoryApiSdk';
import { IPGInventoryApiConfig } from '../api/IInventoryApiSdk';
import { Inventory } from '../internal/domain/entities/Inventory';
import { name, version } from './packageInfo';
import { HttpTransport } from '@estee/elc-http-transport';

export const diContainer = new Container();
export const serviceNames = {
    config: 'CONFIG',
    serviceBusController: 'SERVICE_BUS_CONTROLLER',
    inventoryService: 'INVENTORY_SERVICE',
    inventoryRepository: 'INVENTORY_REPOSITORY',
    httpTransport: 'HTTP_TRANSPORT',
    inventoryApiSdk: 'INVENTORY_API_SDK',
    inventoryEntity: 'INVENTORY_ENTITY'
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setConfig = (configJSON: any) => {
    diContainer.config = configJSON;
};

diContainer
    .register(serviceNames.config, (c) => new Config(c.config))
    .register(serviceNames.inventoryService, (c) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return new InventoryService((config: any) => {
            setConfig(config);

            return c.get(serviceNames.serviceBusController);
        });
    })
    .register(serviceNames.inventoryEntity, () => new Inventory())
    .register(serviceNames.inventoryRepository, (c) => {
        const payload: IInventoryRepository = {
            inventory: c.get(serviceNames.inventoryEntity),
            apiSdk: c.get(serviceNames.inventoryApiSdk)
        };

        return new InventoryRepository(payload);
    })
    .register(
        serviceNames.serviceBusController,
        (c) => new ServiceBusController(c.get(serviceNames.inventoryRepository))
    )
    .register(serviceNames.httpTransport, () => new HttpTransport())
    .register(serviceNames.inventoryApiSdk, (c) => {
        const { config = {} } = c.get(serviceNames.config);
        const { inventoryConfig = {} } = config;
        const { pgInventoryParams } = inventoryConfig;

        const payload: IPGInventoryApiConfig = {
            pgInventoryParams,
            config: {
                httpTransport: c.get(serviceNames.httpTransport),
                businessUnitId: c.get(serviceNames.config).businessUnitId
            },
            serviceName: name,
            serviceVersion: version
        };

        return new InventoryApiSdk(payload);
    });

const { lazyInject } = getDecorators(diContainer);

export { lazyInject };
