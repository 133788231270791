import { Service } from '@estee/elc-service';
import { IAppConfig, ServiceInitializer } from '@estee/elc-universal-utils';

const { name, version } = __serviceInfo__;

export class InventoryService extends Service {
    constructor(serviceInitializer: ServiceInitializer) {
        super(name, version, serviceInitializer);
    }

    public async start(config: IAppConfig) {
        const service = await super.start(config);

        return service;
    }
}
