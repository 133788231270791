import { queryProvider, serviceQueries } from '@estee/elc-service-bus';
import { InventoryRepository } from '../internal/repositories/InventoryRepository';

export class ServiceBusController {
    private inventoryRepository: InventoryRepository;

    constructor(inventoryRepository: InventoryRepository) {
        this.inventoryRepository = inventoryRepository;
    }

    @queryProvider(serviceQueries.GET_INVENTORY)
    public getInventory = async (productIds: string[]) => {
        try {
            return await this.inventoryRepository.getInventories(productIds);
        } catch (error) {
            console.warn(error.message);
        }
    };
}
