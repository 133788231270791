import { observable } from 'mobx';
import { IInventoryQueryResponse } from 'src/internal/repositories/InventoryRepository';
import { LEGACY_INVENTORY_STATUSES } from '../../constants/InventoryStatus';

const isLegacyStatus = (status: string | number) => !isNaN(Number(status));

export class Inventory {
    @observable public productId: string = '';
    @observable public skuId: string = '';
    @observable public status: string;
    @observable public highVelocityQuantity?: number;

    public static fromJson(inventory: IInventoryQueryResponse): Inventory {
        const newInventory = new Inventory();
        newInventory.productId = inventory.productId;
        newInventory.skuId = inventory.skuId;
        if (
            isLegacyStatus(inventory.inventoryStatus) &&
            inventory.inventoryStatus in LEGACY_INVENTORY_STATUSES
        ) {
            newInventory.status = LEGACY_INVENTORY_STATUSES[inventory.inventoryStatus];
        }

        const highVelocityQuantity = inventory.highVelocityQuantity;
        if (highVelocityQuantity) {
            newInventory.highVelocityQuantity = highVelocityQuantity;
        }

        return newInventory;
    }
}
