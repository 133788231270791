import { IRPCData } from '../../../api/IInventoryApiSdk';
import { ISku, IInventoryQueryResponse, IProduct } from '../IInventoryRepository';

const transformProductIntoInventory = (
    skusInvData: IInventoryQueryResponse[],
    product: IProduct
) => {
    product?.skus.forEach((sku: ISku) => {
        const skuInvData: IInventoryQueryResponse = {
            productId: product.PRODUCT_ID.replace('PROD', ''),
            skuId: sku.PRODUCT_CODE,
            inventoryStatus: sku.INVENTORY_STATUS,
            highVelocityQuantity: sku.highVelocityQty
        };

        skusInvData.push(skuInvData);
    });

    return skusInvData;
};

export const formatInventoryFromJSONRPC = ({
    response
}: IRPCData): IInventoryQueryResponse[] | [] => {
    const products = (response?.data || [])[0]?.result?.value?.products || [];

    return products.reduce(transformProductIntoInventory, []);
};
